'use strict';

angular.module('windmanagerApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('report-config', {
        url: '/report-config',
        template: '<report-config></report-config>',
        authenticate: true,
        data: {
          bodyClass: ''
        }
      });
  });
